<template>
  <b-overlay :show="loadingMessages" spinner-variant="primary">
    <k-data-table
      id="MessagesTable"
      :options="dtOptions"
      :dataList="messages"
      class="table-hover simple-table dataTable"
      ref="datatable"
    >
    </k-data-table>
  </b-overlay>
</template>

<script>
import { mapGetters } from 'vuex';
import $ from 'jquery';
import { NotificationFunctions } from '../../functions/NotificationFunctions';
import { uzenetService } from '../../services/UzenetService';

export default {
  name: 'messages-table',
  model: {
    prop: 'selected',
    event: 'change',
  },
  data() {
    return {
      loadingMessages: false,
      messages: [],
      timerId: null,
      currentId: null
    };
  },
  async mounted() {
    await this.GetMessages();
    await this.GetCurrentQueueStatus();
  },
  beforeDestroy() {
    if (this.timerId) clearTimeout(this.timerId);
  },
  created() {
  },

  methods: {
    SetSelectedItem(ids) {
      this.$emit('change', ids);
    },
    async GetMessages() {
      this.loadingMessages = true;
      try {
        let messages = await uzenetService.GetMessages();
        this.messages = Object.freeze(messages);
      } catch (error) {
        NotificationFunctions.AjaxError({ error });
      }
      this.loadingMessages = false;
    },
    DrawSelectsOnDT(ids) {
      var selected = ids
        .map(m => {
          return `[data-id="${m.id}"]`;
        })
        .join(',');
      if (selected) {
        $(this.$refs.datatable.$el)
          .DataTable()
          .rows(selected, { selected: false })
          .select();
      }
      let notSelected = $(this.$refs.datatable.$el)
        .find('tr[data-id]')
        .not(selected);

      if (notSelected.length > 0) {
        $(this.$refs.datatable.$el)
          .DataTable()
          .rows(notSelected, { selected: true })
          .deselect();
      }
    },
    EmailsStatusElement(id, status){
      if (status == null)
        return `<span data-email="${id}"></span>`;
      return `<span data-email="${id}" class="text-blue" style="max-width: 150px; white-space: pre-wrap"><i class="fas fa-envelope"></i> ${status.EmailsSent}/${status.EmailsTotal} ${!status.Fails ? '' : ('<br/>Hibák száma: ' + status.Fails + '; utolsó hiba: ' + status.LastError)}</span>`;
    },
    async GetCurrentQueueStatus() {
      let status = await uzenetService.GetCurrentQueueStatus();
      let id = this.currentId ?? status?.UzenetId ?? 0;
      $(`span[data-email=${id}]`).replaceWith(this.EmailsStatusElement(id, status));
      this.currentId = status?.UzenetId;
      this.timerId = setTimeout(this.GetCurrentQueueStatus, 10000);
    }
  },
  computed: {
    ...mapGetters({}),
    dtOptions() {
      let vm = this;
      let tableData = this.tableData;
      let dtOptions = {
        initComplete: function(settings, json) {
          var table = '#' + settings.sTableId;

          $(table + ' .clear-searchbar').click(function() {
            $('.dataTables_filter .form-control').val('');
            $(vm.$refs.datatable.$el)
              .DataTable()
              .search('')
              .draw();
          });
        },
        mark: {
          className: 'mark-red',
        },
        order: [[3, 'desc']],
        bSortClasses: false,
        aoColumns: [
          {
            sTitle: 'Feladó',
            sClass: 'width-fit-content',
            mRender: function(data, type, row, meta) {
              return row.Felado?.Text ?? 'Nincs';
            },
          },
          {
            sTitle: 'Címzettek',
            sClass: '',
            mRender: function(data, type, row, meta) {
              let cimzettSzervezetek = (row.CimzettSzervezetek ?? [])
                .map(m => m.Text)
                .join(', ');
              let cimzettFelhasznalok = (row.CimzettFelhasznalok ?? [])
                .map(m => m.Text)
                .join(', ');

              return [cimzettSzervezetek, cimzettFelhasznalok]
                .filter(f => f)
                .join('<br/>');
            },
          },
          {
            sTitle: 'Tartalma',
            sClass: '',
            mRender: function(data, type, row, meta) {
              var array = [row.Targya, row.Tartalma];
              if (row.KulsoLink != null)
                array.push('<a href="' + row.KulsoLink + '">Külső link</a>');
              if (row.BelsoLink != null)
                array.push(
                  '<a href="' + row.BelsoLink + '">Belső link</a>'
                );
              return array.filter(f => f).join('<br/>');
            },
          },
          {
            sTitle: 'Üzenetküldés időpontja',
            sClass: 'width-fit-content',
            mRender: function(data, type, row, meta) {
              if (row.UzenetKuldesDatuma) {
                return vm.$options.filters.toDateTime(row.UzenetKuldesDatuma);
              }
              return 'Nincs';
            },
          },
          {
            sTitle: 'Státusz',
            sClass: 'width-fit-content',
            mRender: function(data, type, row, meta) {
              return vm.EmailsStatusElement(row.Id, row.Status);
            },
          },
        ],
        pageLength: 25,
        responsive: false,
        deferRender: true,
        select: false,
        sDom: `<'row dt-panelmenu align-items-center'<'col-4 'i><'col-8 dt-search'<f>>> 
          <'row'<'col-sm-12'tr>>
          <'row dt-panelfooter mt-1'<'col-3' B><'col-9 dt-lp d-flex justify-content-end'l p>>`,
        //buttons: ['copy', 'excel', 'pdf', 'print'],
        drawCallback: function(settings) {
          var table = '#' + settings.sTableId + '_wrapper';
          var lastRowColSpan = settings.aoColumns.length;
        },
        createdRow: function(row, data, rowIndex) {
          $(row).attr('data-id', data.Id);
        },
        buttons: [
          {
            extend: 'print',
            text: '<i class="fas fa-print text-grey"></i>',
            className: 'btn btn-primary',
          },
          {
            extend: 'excelHtml5',
            text: '<i class="fas fa-download mr-0 text-grey"></i>',
            className: 'btn btn-primary',
          },
        ],
      };

      return dtOptions;
    },
  },
  watch: {
    selected: {
      handler: async function(curr) {
        await this.$nextTick();
        this.DrawSelectsOnDT(curr);
      },
      deep: true,
    },
  },
  props: {
    selected: {
      type: Array,
      default: function() {
        return [];
      },
    },
  },
};
</script>
